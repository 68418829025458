<template>
  <el-dialog title="新增" :close-on-click-modal="false" width="35%" :visible.sync="addVisible" :before-close="closeEvent">
    <el-form ref="form" label-width="150px">
      <el-form-item size="small" label="请求地址:" required>
        <el-input v-model="apiDomain" style="width: 250px" placeholder="请输入请求地址" maxlength="32"
                  show-word-limit></el-input>
      </el-form-item>
      <el-form-item size="small" label="服务商序列号:" required>
        <el-input v-model="vendorSn" style="width: 250px" placeholder="请输入服务商序列号" maxlength="32"
                  show-word-limit></el-input>
      </el-form-item>
      <el-form-item size="small" label="服务商密钥:" required>
        <el-input size="small" v-model="vendorKey" style="width: 250px" placeholder="请输入服务商密钥" maxlength="255"
                  show-word-limit></el-input>
      </el-form-item>
      <el-form-item size="small" label="APPID:" required>
        <el-input size="small" v-model="appId" style="width: 250px" placeholder="请输入APPID" maxlength="32"
                  show-word-limit></el-input>
      </el-form-item>
      <el-form-item size="small" label="终端激活码:" required>
        <el-input size="small" v-model="code" style="width: 250px" placeholder="请输入终端激活码" maxlength="255"
                  show-word-limit></el-input>
      </el-form-item>
      <el-form-item size="small" label="DEVICE_ID:" required>
        <el-input size="small" v-model="deviceId" style="width: 250px" placeholder="请输入DEVICE_ID" maxlength="255"
                  show-word-limit></el-input>
        <p>内容自行定义,为了方便识别
          例：公司名称缩写大写字母+数字</p>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="closeEvent">取 消</el-button>
            <el-button size="small" type="primary" @click="savePayParam">确 定</el-button>
        </span>
  </el-dialog>
</template>
<script>

  export default {
    data() {
      return {
        apiDomain: '',
        vendorSn: '',
        vendorKey: '',
        appId: '',
        code: '',
        deviceId: '',
      }
    },
    props: {
      addVisible: Boolean
    },
    methods: {
      closeEvent() {
        this.$emit('closeInsert')
      },
      async savePayParam() {
        let data = {
          apiDomain: this.apiDomain,
          vendorSn: this.vendorSn,
          vendorKey: this.vendorKey,
          deviceId: this.deviceId,
          appId: this.appId,
          code: this.code,
        };
        // if (!this.brandNo) {
        // this.$message.error(res.message);
        //   return
        // }
        // if (!this.brandName) {
        // this.$message.error(res.message);
        //   return
        // }

        let res = await this.$post("/admin/save_bbiPayParam", data)
        if (res.code == 200) {
          this.$message.success(res.message);
          this.$emit('addSuccess')
        } else {
          this.$message.error(res.message);
        }
      }
    }
  }
</script>
