<template>
  <div>
    <div class="e-breadcrumb">支付参数管理</div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">
          <div style="margin-bottom: 35px">
            <div class="handle-box">
              <span class="v-form-label">APPID:</span>
              <el-input placeholder="APPID" size="small" style="width:265px" v-model="appId" clearable
                        class="handle-input mr10"></el-input>
              <div style="margin-left: 230px">
                <el-row type="flex" justify="end">
                  <el-button size="small" class="e-button" type="primary" @click="searchEvent">查询
                  </el-button>
                  <el-button size="small" class="e-button" type="success" @click="addEvent()">新增
                  </el-button>
                </el-row>
              </div>
            </div>
          </div>

          <div class="e-table-wrapper">
            <table cellpadding="0" cellspacing="0" border="0" width="100%" style="text-align: center">
              <thead>
              <tr>
                <th width="130" class="th-l">服务商序列号</th>
                <th width="130" class="th-l">APPID</th>
                <th width="130" class="th-l">DEVICE_ID</th>
                <th width="130" class="th-l">启用/禁用</th>
                <th width="180" class="th-l">操作</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row,index) in list" :key="row.id" :class="{ on: index % 2 == 0, off: index % 2 != 0 }">
                <td class="td-l"><span>{{row.vendorSn}}</span></td>
                <td class="td-l">{{row.appId}}</td>
                <td class="td-l"><span>{{row.deviceId}}</span></td>
                <td class="td-l">
                  <p style="cursor: pointer;color: #1b80ff" v-if="row.payStatus==1">启用</p>
                  <p style="cursor: pointer;color: #1b80ff" v-if="row.payStatus==0">禁用</p>
                </td>
                <td class="td-l">
                  <el-button type="text" icon="el-icon-delete" class="red" @click="removeEvent(index)">删除</el-button>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="pagination">
              <el-pagination
                background
                @current-change="handleCurrentChange"
                :pager-count="7"
                layout="total, prev, pager, next,jumper"
                :total="pagination.count"
                :page-count="pagination.pages"
                :current-page="pagination.pageNumber"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Insert v-if="addVisible" :addVisible="addVisible" @closeInsert="closeInsert" @addSuccess="addBbiBrandSuccess"/>

    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
      <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="delVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="delBbiBrand">确 定</el-button>
            </span>
    </el-dialog>

  </div>
</template>

<script>
  import Insert from './insert'

  export default {
    data() {
      return {
        appId: '',
        list: [],
        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },
        addVisible: false,
        editVisible: false,
        delVisible: false,
        editItem: {},
      }
    },
    async created() {
      this.searchEvent();
    },
    methods: {
      handleCurrentChange(page) {
        this.requestSearch(page)
      },
      searchEvent() {
        this.requestSearch()
      },
      async requestSearch(page) {
        let currentPage = page || 1;
        let data = {
          appId: this.appId,
          isDel: '0',
          size: this.size == '' ? '10' : this.size,
          currentPage
        }
        let res = await this.$get("/admin/getBbiPayParamForPage", data)
        this.list = res.dataSet.list;
        this.pagination = {
          count: res.dataSet.count,
          pageNumber: res.dataSet.pageNumber,
          pageSize: res.dataSet.pageSize,
          pages: res.dataSet.pages
        }
      },

      addEvent() {
        this.addVisible = true;
      },

      editEvent(index) {
        this.editItem = this.list[index]
        this.editVisible = true;
      },

      async removeEvent(index) {
        this.editItem = this.list[index]
        this.delVisible = true;
      },

      //删除BbiBrand
      async delBbiBrand() {
        let data = {
          id: this.editItem.id
        };
        let res = await this.$post("/admin/delete_bbiPayParam", data)
        if (res.code == 200) {
          this.searchEvent();
          this.$message.success(res.message);
          this.delVisible = false;
        } else {
          this.$message.error(res.message);
        }
      },

      closeInsert() {
        this.addVisible = false;
      },

      closeEdit() {
        this.editVisible = false;
      },

      addBbiBrandSuccess() {
        this.closeInsert();
        this.searchEvent();
      },

      editBbiBrandSuccess() {
        this.closeEdit();
        this.searchEvent();
      }
    },
    components: {
      Insert,
    }
  }

</script>

<style scoped>
  .th-l {
    text-align: center;
    padding-right: 10px;
    font-size: 14px;
  }

  .td-l {
    padding: 10px 12px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  .e-table-wrapper tbody .el-button {
    padding: 3px 0px;
  }
    .off {
  background-color: #fff;
}
.on {
  background-color: #ececea;
}
</style>
